import React from 'react';
import PropTypes from 'prop-types';
// import { Row, Col } from 'antd';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';

import HeroSection from '../components/city-enabling/HeroSection';
import PRIMEFocusComponent from '../components/city-enabling/PRIMEFocusComponent';
import LinesAndNodesComponent from '../components/LinesAndNodesComponent';
// import LinesAndNodesComponent from '../components/LinesAndNodesComponent';

const StyledDivWrapper = styled.div`
  padding-top: 89px;
  overflow: hidden;
`;

function CSRPage({ location }) {
  return (
    <Layout source="City Enabling Page">
      <SEO
        title="City Enabling"
        description="The Leading Commercial Real Estate Consultancy Firm in the Philippines."
        meta={[
          {
            property: 'og:url',
            content: location.href,
          },
          {
            name: 'keywords',
            content: `PRIME FOCUS ON THE CITY BUILT AS A CAPITAL,
              DAVAO CITY PRIMARY REAL ESTATE CONSULTANT,
              REVIVING THE HEART OF MINDANAO,
              WAKING THE SLEEPING GIANT OF THE NORTH: CLARK PAMPANGA`,
          },
        ]}
      />
      <StyledDivWrapper>
        <div className="line-node-wrapper">
          <HeroSection />
          <LinesAndNodesComponent set={1} position="right" />
          <PRIMEFocusComponent />
        </div>
      </StyledDivWrapper>
    </Layout>
  );
}

CSRPage.propTypes = {
  location: PropTypes.object,
};
export default CSRPage;
