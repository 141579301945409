import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { Link } from 'gatsby';

import coverImage from '../../../static/images/csr/city-enabling-bg.jpg';

const StyledDiv = styled.div`
  text-align: center;

  background-image: linear-gradient(
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.28),
      rgba(0, 0, 0, 0.8)
    ),
    url(${coverImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 75vh;
  max-height: 450px;
  max-width: 1440px;
  margin: auto;
  position: relative;
  margin-bottom: 100px;
  @media only screen and (max-width: 426px) {
    max-height: initial;
  }
  .styled-row {
    height: 100%;
    color: ${props => props.theme.whitePalette.original};
  }

  .headline {
    h1 {
      font-size: 2.75rem;
      font-weight: bolder;
      @media only screen and (max-width: 426px) {
        font-size: 2.3rem;
      }

      @media only screen and (min-width: 550px) and (max-width: 776px) {
        font-size: 2.3rem;
      }
    }
    h1:first-child {
      margin-bottom: 0px;
    }
    h1:last-child {
      color: ${props => props.theme.yellowPalette.original};
    }
  }

  img {
    box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.41);
    margin-bottom: 0px;
  }

  p {
    font-size: 1.35em;
    line-height: 1.3;

    @media only screen and (min-width: 550px) and (max-width: 776px) {
      font-size: 1.15em;
      line-height: 1;
    }
  }

  .header-action-buttons {
    position: relative;
    bottom: 0px;
    margin-top: -40px;
    max-width: 1440px;
    overflow: hidden;
    padding-bottom: 5px;

    .action-button {
      > div {
        background: ${props => props.theme.whitePalette.original};
        color: ${props => props.theme.primeBluePalette.original};
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.35);
        width: 300px;
        height: 100%;

        padding: 10px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;

        :hover {
          background: ${props => props.theme.primeBluePalette.original};
          h3 {
            color: ${props => props.theme.primeYellowPalette.original};
          }
        }
      }
      > .active {
        background: ${props => props.theme.primeBluePalette.original};
        h3 {
          color: ${props => props.theme.primeYellowPalette.original};
        }
      }
    }
  }

  h3 {
    margin-bottom: 0px;
    font-weight: bolder;
  }
  h1 {
    font-weight: bolder;
  }
  @media only screen and (max-width: 426px) {
    /* height: 50vh; */
  }

  .add-diamond-line:after {
    border-color: ${props => props.theme.whitePalette.original};
  }

  .title-section {
    font-size: 4rem;
    color: ${props => props.theme.primeYellowPalette.original};
    border-bottom: 2px solid ${props => props.theme.whitePalette.original};
    @media only screen and (max-width: 426px) {
      font-size: 3rem;
    }
  }

  p {
    margin-top: 30px;
    color: ${props => props.theme.whitePalette.original};
  }

  .ant-row {
    height: 100%;
  }
`;

function HeroSection() {
  return (
    <StyledDiv>
      <Row type="flex" align="middle" justify="center">
        <Col xs={22} sm={22} md={22} lg={18} xl={16} xxl={16}>
          <h1 className="title-section add-diamond-line">CITY ENABLING</h1>
          {/* diamond with line */}
          <p>
            Starting in Quezon City and expanding to Davao City, PRIME
            Philippines looks beyond Centra Business Districts for its market.
            Rather than focusing on existing demand catered by already
            established business centers, PRIME looks to take part and jumpstart
            rising business districts to their full potential.
          </p>
        </Col>
      </Row>

      <div className="header-action-buttons">
        <Row type="row" justify="center" gutter={[20, 0]} align="stretch">
          <Col className="action-button">
            <div className="active">
              <Link to="/city-enabling">
                <h3>CITY ENABLING</h3>
              </Link>
            </div>
          </Col>
          <Col className="action-button ">
            <div>
              <Link to="/corporate-social-responsibility">
                <h3>CORPORATE SOCIAL RESPONSIBILITY</h3>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </StyledDiv>
  );
}

export default HeroSection;
