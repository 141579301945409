/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import Slider from 'react-slick';
import { Collapse } from 'react-collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Scroll from 'react-scroll';

// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'gatsby';

// const { scroller } = Scroll;
const { Element } = Scroll;

const contentDiv = [
  {
    title: `PRIME’s focus on the city built as a capital`,
    content: `
    PRIME Philippines’ humble beginnings started out in Quezon City. Understanding the opportunity present based on the city’s road networks and labor pool, PRIME extensively covered and marketed the city, being the first real estate consultancy firm to produce a market report focused on the city. PRIME honed its knowledge of the local markets and successfully leased out multiple office and retail buildings, along with facilitating the sale of more than 20 prime commercial lots. In 2014, PRIME facilitated the purchase of a prominent commercial center, which recorded the largest single-ticket real estate transaction in the city.
    <br/>
    <br/>
    Aside from property transactions, PRIME has served as development consultant for numerous commercial centers and office buildings in the city, contributing to the city’s growing commerce through market-based, data-driven recommendations and insights.
    `,
    images: [
      {
        src: '../../images/city-enabling/prime focus/prime_focus_0000.png',
        link:
          'https://research.primephilippines.com/publications/2019-Midyear-Property-Market-Repor',
      },
      {
        src: '../../images/city-enabling/prime focus/prime_focus_0001.png',
        link:
          'https://research.primephilippines.com/publications/2018-Midyear-Market-Report',
      },
      {
        src: '../../images/city-enabling/prime focus/prime_focus_0002.png',
        link:
          'https://research.primephilippines.com/publications/2018-philippine-real-estate-outlook/',
      },
      {
        src: '../../images/city-enabling/prime focus/prime_focus_0005.png',
      },
      {
        src: '../../images/city-enabling/prime focus/prime_focus_0003.png',
        link:
          'https://research.primephilippines.com/publications/Quezon-City-Boom-Of-Office-Spaces',
      },
      {
        src: '../../images/city-enabling/prime focus/prime_focus_0004.png',
        link:
          'https://research.primephilippines.com/publications/2015-Property-Market-Report',
      },
    ],
  },
  {
    title: `Davao City’s Primary Real Estate Consultant`,
    content: `A proven partner for commercial real estate developers, PRIME works hand-in-hand with property owners and their development teams to ensure full occupancy for projects handled. Due to their dynamic approach to project marketing, PRIME is the premier option for the bold developer. PRIME’s approach to project marketing involves innovation and strong utilization of technology.`,
    images: [
      {
        src: '../../images/city-enabling/davao city/davao_city_0000.jpg',
      },
      {
        src: '../../images/city-enabling/davao city/davao_city_0001.jpg',
      },
      {
        src: '../../images/city-enabling/davao city/davao_city_0002.jpg',
      },
      {
        src: '../../images/city-enabling/davao city/davao_city_0003.jpg',
      },
      {
        src: '../../images/city-enabling/davao city/davao_city_0004.jpg',
      },
    ],
  },
  {
    title: `Reviving the heart of Mindanao`,
    content: `Part of its creed to aid key cities, Founder & CEO, Jet Yu personally went to Marawi, bringing in investors with him to see the current landscape and needs of the city stricken by war. Upon assessment of Mr. Yu along with the research team, re-establishment of commercial activity is a must, along with continuous aid in redeveloping war-torn areas. Retail merchandise is seen as a potential economy-starter along with industrial parks and equipment for agriculture. Though not one of the main business districts of Mindanao, PRIME Philippines believes that synergy among cities is essential for the continuous growth of Mindanao, and the aid PRIME is bringing Marawi is not just for the sake of one city, but that of the entire Mindanao.`,
    images: [
      {
        src: '../../images/city-enabling/reviving marawi/marawi_0000.jpg',
      },
      {
        src: '../../images/city-enabling/reviving marawi/marawi_0001.jpg',
      },
      {
        src: '../../images/city-enabling/reviving marawi/marawi_0002.jpg',
      },
      {
        src: '../../images/city-enabling/reviving marawi/marawi_0003.jpg',
      },
      {
        src: '../../images/city-enabling/reviving marawi/marawi_0004.jpg',
      },
    ],
  },
  {
    title: `Waking The Sleeping Giant of the North: Clark, Pampanga`,
    content: `Upon identification and transition of Clark Global City to a commercial hub, PRIME immediately saw the potential of the next metro city in the north as a catalyst for growth for the city and its population. PRIME continues to facilitate the entry of potential investors and locators in order to establish the long-slumbering giant. The company is also in talks with the different locators present in Clark Global City, in order to solidify their development plans and effectively bring to rise the next Metro in the North.`,
    images: [
      {
        src: '../../images/city-enabling/sleeping giant/sleeping_giant_001.jpg',
      },
      {
        src: '../../images/city-enabling/sleeping giant/sleeping_giant_002.jpg',
      },
      {
        src: '../../images/city-enabling/sleeping giant/sleeping_giant_003.jpg',
      },
      {
        src: '../../images/city-enabling/sleeping giant/sleeping_giant_004.jpg',
      },
    ],
  },
];

const StyledDivWrapper = styled.div`
  text-align: center;
  h3 {
    margin-bottom: 30px;
  }

  .content-title {
    background: ${props => props.theme.primeBluePalette.original};
    color: ${props => props.theme.primeYellowPalette.original};
    padding: 6px 12px;
    box-shadow: 5px 5px 0px 0px ${props => props.theme.primeBluePalette.dark};
    text-transform: uppercase;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .add-diamond-line:after {
    border-color: ${props => props.theme.primeBluePalette.original};
  }

  .content-div {
    border-bottom: 2px solid ${props => props.theme.primeBluePalette.original};
    margin-bottom: 60px;
    :last-child {
      border-bottom: 0px solid white;
    }
  }

  p {
    font-size: 1.1em;
  }

  .img-slider {
    margin-bottom: 0px;
    box-shadow: -5px 5px 0px 0px rgba(0, 31, 61, 1);
  }

  .slick-slide {
    padding: 16px;
  }

  .prev-slider {
    display: block;
    position: absolute;
    top: calc(50% - 35px);
    left: -50px;
    font-size: 50px;
    cursor: pointer;
  }

  .next-slider {
    display: block;
    position: absolute;
    top: calc(50% - 35px);
    right: -50px;
    font-size: 50px;
    cursor: pointer;
  }

  .read-more {
    color: ${props => props.theme.primeBluePalette.original};
    border: 1px solid ${props => props.theme.primeBluePalette.original};
    font-weight: bold;
    padding: 6px 10px;

    :hover {
      background-color: ${props => props.theme.primeBluePalette.original};
      color: ${props => props.theme.primeYellowPalette.original};
      cursor: pointer;
    }
  }

  .ReactCollapse--collapse {
    min-height: 63px;
    transition: height 500ms;
    transition-delay: 300ms;
  }

  .ReactCollapse--content {
    position: relative;
    > .collapsed-div:before {
      content: '';
      width: 100%;
      height: 63px;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 43%,
        rgba(255, 255, 255, 0.84) 100%
      );
    }
  }

  svg:hover {
    cursor: pointer;
  }

  a:hover {
    img {
      opacity: 0.8;
    }
  }
`;

function NextArrow(props) {
  // eslint-disable-next-line react/prop-types
  const { style, onClick } = props;
  return (
    <div
      className="next-slider"
      style={{ ...style, display: 'block', color: '#003366' }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon="chevron-right" fixedWidth />
    </div>
  );
}

function PrevArrow(props) {
  // eslint-disable-next-line react/prop-types
  const { style, onClick } = props;
  return (
    <div
      className="prev-slider"
      style={{ ...style, display: 'block', color: '#003366' }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon="chevron-left" fixedWidth />
    </div>
  );
}

const settings = {
  initialSlide: 0,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function PRIMEFocusComponent() {
  const [activeIndex, setActiveIndex] = useState(-1);

  return (
    <StyledDivWrapper>
      <Row type="flex" align="middle" justify="center">
        <Col xs={20} sm={20} md={20} lg={18} xl={18} xxl={18}>
          {contentDiv.map((content, i) => (
            <Element
              name={`search-result-${i}`}
              key={content.title}
              className={`content-div ${
                i !== contentDiv.length - 1 ? 'add-diamond-line' : ''
              }`}
            >
              <div style={{ marginBottom: 20 }}>
                <h3 className="content-title">{content.title}</h3>
                <Collapse
                  isOpened={i === activeIndex}
                  initialStyle={
                    i === activeIndex
                      ? { height: 'auto', overflow: 'initial' }
                      : { height: '63px', overflow: 'hidden' }
                  }
                >
                  <div className={i !== activeIndex ? 'collapsed-div' : ''}>
                    <div>
                      <p
                        dangerouslySetInnerHTML={{ __html: content.content }}
                      />

                      <Slider {...settings}>
                        {content.images.map((img, index) => (
                          <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${content.title}${index}`}
                            className="history-wrapper"
                          >
                            {!img.link ? (
                              <img
                                className="img-slider"
                                src={img.src}
                                alt={content.title}
                              />
                            ) : (
                              <Link to={img.link}>
                                <img
                                  className="img-slider"
                                  src={img.src}
                                  alt={content.title}
                                />
                              </Link>
                            )}
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </Collapse>
              </div>
              {activeIndex !== i ? (
                <div>
                  <FontAwesomeIcon
                    icon="chevron-down"
                    size="2x"
                    fixedWidth
                    onClick={() => {
                      setActiveIndex(i);
                    }}
                  />
                </div>
              ) : null}
            </Element>
          ))}
        </Col>
      </Row>
    </StyledDivWrapper>
  );
}

export default PRIMEFocusComponent;
